
  import { Component, Prop, Vue } from 'vue-property-decorator';

  @Component
  export default class DatePicker extends Vue {
    @Prop() readonly minDate: string | undefined;
    @Prop() readonly maxDate: string | undefined;

    isOpen = false;
    value = '';

    save(): void {
      this.isOpen = false;
      this.$emit('change', this.value)
    }
  }
