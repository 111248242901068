
  import { Component, Prop, Vue } from "vue-property-decorator";
  import { namespace } from "vuex-class";
  import { TextValuePair } from "@/interfaces";
  import { getLocationsFromConceptList } from "@/helpers/concepts";
  import { ConceptModel } from "@/models";

  const conceptsStore = namespace('concepts');

  @Component
  export default class LocationsFilter extends Vue {
    @Prop({ default: 'orders.location' }) readonly label!: string;
    @Prop({ default: () => ([]) }) readonly selectedValues!: string[];

    @conceptsStore.State('conceptsForUser')
    readonly userConcepts!: ConceptModel[];

    get items(): TextValuePair[] {
      return getLocationsFromConceptList(this.userConcepts);
    }
  }
