
import { Component, Vue } from 'vue-property-decorator';
import DatePicker from '@/components/DataPicker.vue';
import LocationsFilter from '@/components/filters/Locations.vue';
import { getCurrentDate, getDiff } from '@/helpers/date';

@Component({ components: { DatePicker, LocationsFilter } })
export default class HolidaysModal extends Vue {
  from = '';
  to = '';
  locationsId: number[] = [];

  get isValid(): boolean {
    return !!(this.locationsId.length && this.from && this.to);
  }

  get minDate(): string | Date {
    const isInFuture = getDiff(this.from) > 0;
    return isInFuture ? this.from : getCurrentDate();
  }

  save(): void {
    this.$emit('submit', {
      from: this.from,
      to: this.to,
      locationsId: this.locationsId,
    });

    this.closeModal();
  }

  closeModal(): void {
    this.$emit('close');
  }
}
