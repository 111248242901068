
  import { Component, Vue } from 'vue-property-decorator';
  import { namespace } from 'vuex-class';
  import { DataTableHeader } from "vuetify";
        
  import HolidaysModal from '@/components/modals/Holidays.vue';
  import { Holiday } from '@/interfaces';
  import { AddHolidayPayload } from '@/store/interfaces';
  import { toLocalDate } from '@/helpers/date';
  import { getLocale } from '@/helpers/user';

  const holidaysStore = namespace('holidays');

  @Component({ components: { HolidaysModal } })
  export default class HolidaysPage extends Vue {
    showModal = false;
    holidays: Holiday[] = [];

    get headers(): DataTableHeader[] {
      return [
        { value: 'location', text: this.$t('holidays.location') as string },
        { value: 'dates', text: this.$t('holidays.dates') as string },
        { value: 'ops', text: '', align: 'end' },
      ]
    }

    @holidaysStore.Action
    getHolidays!: () => Promise<Holiday[]>;

    @holidaysStore.Action
    add!: (data: AddHolidayPayload) => Promise<Holiday[]>

    @holidaysStore.Action
    remove!: (id: number) => Promise<boolean>;

    mounted(): void {
      this.getItems();
    }

    async getItems(): Promise<void> {
      this.holidays = await this.getHolidays();
    }

    async addHolidays(data: AddHolidayPayload): Promise<void> {
      await this.add(data);
      this.getItems();
    }

    getDates(date: string): string {
      return toLocalDate(date, getLocale(), true);
    }

    async removeItem(id: number): Promise<void> {
      if (confirm(this.$t('general.areYouSure') as string)) {
        if (await this.remove(id)) {
          this.holidays = this.holidays.filter(item => item.id !== id);
        }
      }
    }
  }
